.toggle-ios {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0rem 0.5rem;
  }
  
  .checkbox-ios {
    display: none;
  }
  
  .label-ios {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d9d9d9;
    border-radius: 34px;
    transition: background-color 0.4s;
  }
  
  .label-ios::after {
    content: '';
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: white;
    top: 4px;
    left: 4px;
    transition: transform 0.4s;
  }
  
  .checkbox-ios:checked + .label-ios {
    background-color: #66bb6a; 
  }
  
  .checkbox-ios:checked + .label-ios::after {
    transform: translateX(26px);
  }