@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100..700&display=swap");

:root {
  --font: "Roboto Mono", monospace;

  --general-bg-color: #000000;
  --general-2nd-color: #808080;
  --general-3nd-color: #111111;
  --general-font-color: #ffffff;
}

html,
body,
#root {
  margin: 0;
  height: 100%;
}

#error-page {
  margin: 2rem;
}

.central-block {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  background-color: var(--general-bg-color);
  color: var(--general-font-color);
}

body * {
  font-family: var(--font);
  text-decoration: none;
}

.tool-btn,
.btn-login {
  color: white;
  background-color: black;
  font-weight: 400;
  font-size: 1.5rem;
  border-radius: 10px;
  width: 90%;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 3rem;
  margin: 1rem 0rem;
  padding: 0.5rem;

  border: 0rem;

  user-select: none;
  cursor: pointer;

  transition: 0.1s;
}

.edit-btn-save:active,
.edit-btn-delete:active,
.tool-btn:active,
.btn-login:active {
  color: black;
  background-color: white;
}

.enter-form {
  border: 1px solid var(--general-2nd-color);
  border-radius: 10px;

  padding: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
}

.login-input {
  background-color: white;
  border-radius: 10px;
  width: 90%;
  height: 3rem;
  margin: 0.5rem 0rem;

  padding: 0rem 0.5rem;
  border: 0rem;
}

.login-form {
  width: 35rem;
  height: 30rem;
  background-color: var(--general-3nd-color);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 2rem;
}

.logo {
  height: 5rem;
  width: 5rem;
}

/* AdminPanel */

.admin-panel {
  display: flex;
  height: 100%;
}

.tool-bar {
  background-color: var(--general-3nd-color);

  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}

.tool-bar * {
  margin: 1rem;
}

.error-message {
  font-size: 0.8rem;
  color: red;
  animation: animate_motion 3s 0s both infinite;
}

@keyframes animate_motion {
  0%,
  20% {
    transform: translate3d(0, 0, 0);
  }
  10%,
  14%,
  18%,
  2%,
  6% {
    transform: translate3d(-7px, 0, 0);
  }
  12%,
  16%,
  4%,
  8% {
    transform: translate3d(7px, 0, 0);
  }
}

.result-content {
  padding: 2rem;
  width: 100%;
  overflow-y: scroll;
}

.edit-mode {
  border-radius: 0px 0px 10px 0px;
  position: fixed;
  top: 0rem;
  left: 15%;
   background-color: #ffffff;
  color: #000000;
  width: 50rem;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 1rem 0rem 3rem 1rem;
  
}

.edit-textarea {
  border-radius: 10px;
  border: 0.1px #b3b3b3 solid;
  width: 40rem;
  height: 10rem;
  font-weight: var(--font_weight_3);
  font-size: 1.3rem;
  resize: none;
}

.edit-header {
  margin: 0.5rem 0rem;
  font-weight: var(--font_weight_1);
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
}


.edit-input {
  border-radius: 10px;
  border: 0.1px #b3b3b3 solid;
  width: 40rem;
  padding: 0 0.5rem;
  font-weight: var(--font_weight_3);
  font-size: 1.3rem;
}

.checkbox {
  flex-direction: row;
  align-items: center;
}

.one-size-text {
  width: 20.5rem;
}

.edit-btns {
  margin: 2rem 0rem 0rem;
  display: flex;
  flex-direction: row;
}

.edit-btn-save,
.edit-btn-delete {
  color: #ffffff;
  background-color: #ff0000;
  font-weight: 700;
  font-size: 1.5rem;
  border-radius: 10px;
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 3rem;
  margin: 0rem 0.5rem 0rem 0rem;
  padding: 0.5rem;

  border: 0rem;
  user-select: none;
  cursor: pointer;
  

  transition: 0.1s;
}


.edit-btn-save {
  background-color: #000000;
}

.edit-pencil {
  position: absolute;
  bottom: 0.5rem;
  background-color: #000000;
  color: #ffffff;
  border-radius: 10px;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;

  border: 0rem;
  user-select: none;
  cursor: pointer;
  

  transition: 0.1s;
}

.custom-file-upload {
  color: #ffffff;
  background-color: #a2a2a2;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 10px;
  width: 20rem;
  display: flex;
  align-items: center;
  /* justify-content: center; */

  height: 3rem;
  margin: 0rem 0.5rem 0rem 0rem;
  padding: 0.5rem;

  border: 0rem;
  user-select: none;
  cursor: pointer;
  

  transition: 0.1s;

}